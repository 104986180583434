.manager-phones__title{
    margin: 0;
    padding: 0;
    margin-top: 10px;
}

.manager-phones__name{
    margin-top: 5px;
    font-size: 16px;
    font-weight: 600;
    color: blue;
}

.manager-phones__phone-type{
    margin-top: 5px;
}

.manager-phones__phone{
    display: inline-block;
    margin-top: 5px;
}

.manager-phones__phone-icon{
    display: inline-block;
    background-image: url('../images/phone.svg');
    background-repeat: no-repeat;
    
    width: 20px;
    height: 15px;
    margin-right: 5px;
}