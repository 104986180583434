.infotooltip {
    bottom: 20%;
    left: 50%;
    position: absolute;
    width: 80vw;
    background: #ffffff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    transform: translateX(-50%);
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .infotooltip__icon {
    display: block;
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .infotooltip__text {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    margin-top: 32px;
    margin-left: 36px;
    margin-right: 36px;
  }
  
  .infotooltip__close-button {
    background-image: url('../images/icon-close.svg');
    height: 40px;
    width: 40px;
    position: absolute;
    top: -45px;
    right: -30px;
    border: none;
    background-color: transparent;
    background-size: 100%;
    background-repeat: no-repeat;
    color: #000;
  }
  
  .infotooltip__close-button:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  
  .infotooltip__close-button:focus {
    outline: none;
  }

  .infotooltip__backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background:rgba(0, 0, 0, 0.35)

  }