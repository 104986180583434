.add-payment__field-wrapper{
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 10px;
    
}

.add-payment__label{
    font-size: 20px;
    align-self: center;
    justify-self: center;
}

.add-payment__input{
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
        border: 1px solid rgb(92, 99, 97);

        font-size: 22px;    
}

.add-payment__input:focus{
    outline: 2px solid #59A4F2;
}

.add-payment__submit{
    margin-top: 10px;
    display: block;
    width: 100%;
    background: #59A4F2;
    border-radius: 10px;
    padding: 12px;
    border-color: transparent;

    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.add-payment__submit:hover{
    cursor: pointer;
    background: #FFFFFF;
    color: #59A4F2;
    border-color: #59A4F2;

}

.add-payment__submit:disabled{
    opacity: 0.2;
    cursor:not-allowed;
}

