.returns__title{
    margin-bottom: 2px;
}

.returns__document{
    margin-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid black;

}

.returns__document_stricted{
    text-decoration: line-through;
}

.returns__actions{
    margin-top: 10px;
}

.returns__action-buttons{
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    
}

.returns__button{
    fill: black;
    background-color: transparent;
    border: none;
}

.returns__button:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.returns__button:disabled{
    cursor:not-allowed;
    opacity: 0.2;
    transform: scale(1);
}

.returns__button_ok{
    fill: blue;
}

.returns__button_cancel{
    fill: red;
}

.returns__comment{
    width: 96%;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}