.checkout-movements__cash-register{
    margin-bottom: 10px;
    border: 1px solid black;
    border-radius: 5px;
}

.checkout-movements__cash-register-name{
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid black;
    font-size: 20px;
    font-weight: 600;
}

.checkout-movements__payment{
    display: grid;
    grid-template-columns: 1fr 20% 10%;
    border-bottom: 1px dotted black;  
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    
}

.checkout-movements__sum{
    text-align: right;
    padding-right: 10px;
}

.checkout-movements__document{
    font-size: 10px;
}

.checkout-movements__contractor{
    font-weight: 500;
    font-size: 18px;
}

.checkout-movements__total{
    text-align: right;
    padding-right: 10px;
    font-size: 18px;
    font-weight: 500;
}