.tasks__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tasks__filter-label{
    font-size: 18px;
}

.tasks__filter{
    font-size: 18px;
}