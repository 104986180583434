.login {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.login__input {
    padding-bottom: 13px;
    font-family: inherit;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    border: none;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}