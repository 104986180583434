.payment__back-page{
    background-color: transparent;
    border: none;
    font-size: 24px;
    color:  #59A4F2;
}

.payment__back-page:hover{
    cursor: pointer;
}

.payment__contractor{
    font-weight: 700;
    font-size: 18px;
}

.payment__address{
    margin-top: 10px;
    font-weight: 600;    
}

.payment__address-comment{
    margin-top: 10px;
    color: red;
}

.payment__documents{
    margin-top: 10px;
}

.payment__amoumt-by-documents{
    text-align: right;
    padding-right: 10px;
}

.payment__sum-by-documents{
    font-weight: 600;
}

.payment__actions{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.payment__action-button{
    padding-top: 10px;
    padding-bottom: 10px; 
    padding-left: 5px;
    padding-right: 5px; 
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;    
    border: 1px solid #59A4F2;
    background-color: #59A4F2;
    border-radius: 5px;
}

.payment__action-button_confirm{
    border: 1px solid #ffc107;
    background-color: #ffc107; 
}

.payment__action-button_cancel{
    border: 1px solid red;
    background-color: red; 
}
