.client-phones__list{
    list-style: none;
}

.client-phones__list-item{
    margin-top: 10px;
    font-size: 18px;
}

.client-phones__phone-icon{
    display: inline-block;
    background-image: url('../images/phone.svg');
    background-repeat: no-repeat;
    
    width: 20px;
    height: 15px;
    margin-right: 5px;
 
}