.cash__balance{
    display: grid;
    grid-template-columns: 70px 1fr;
}

.cash__balance-cell{
    border: 1px solid black;
    padding-left: 10px;
    padding-right: 10px;
}

.cash__actions{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cash__button{
    margin-top: 10px;
    display: inline-block;
    background: #ffc107;;
    border-radius: 10px;
    padding: 12px;
    border: 1px solid transparent;
    border-color: transparent;
    

    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;
}

.cash__button:hover{
    cursor: pointer;
    background: #FFFFFF;
    color: #ffc107;;
    border-color: #ffc107;;
}

.cash__button_danger{
    border: 1px solid red;
    background-color: red; 
}