.cancel-delivery{
    margin-top: 20px;
}

.cancel-delivery__submit{
    margin-top: 10px;
    display: block;
    width: 100%;
    background: red;
    border-radius: 10px;
    padding: 12px;
    border-color: transparent;

    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.cancel-delivery__submit:hover{
    cursor: pointer;
    background: #FFFFFF;
    color: red;
    border-color: red;

}

.cancel-delivery__submit:disabled{
    cursor:not-allowed;
    opacity: 0.2;
}

.cancel-delivery__comment{
    width: 100%;
    border: 1px solid #000000;
    border-radius: 5px;
    font-size: 16px;
}
