.flight{
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0px 3px 7px gray;
}

.flight__form{
    display: flex;
    flex-direction: column;
}

.flight__submit{
    margin-top: 10px;
    display: block;
    width: 100%;
    background: #59A4F2;
    border-radius: 10px;
    padding: 12px;
    border-color: transparent;

    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.flight__submit:hover{
    cursor: pointer;
    background: #FFFFFF;
    color: #59A4F2;
    border-color: #59A4F2;

}

.flight__input{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    border: 1px solid rgb(92, 99, 97);
    font-size: 22px;
}