.navigation{    
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid gray;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
    max-width: 600px;
}

.navigation__list{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    list-style: none;
}

.navigation__list-item{
    min-height: 50px;
    min-width: 50px;
}

.navigation__item{
    display: inline-block;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;    
    background-size: contain;
    border-bottom: 4px solid transparent;
}

.navigation__item_active, .active{
    border-bottom: 4px solid gray;
}

.navigation__item_route{
    background-image: url('../img/route.png');
}

.navigation__item_route-list{
    background-image: url('../img/routs.png');
}

.navigation__item_cash{
    background-image: url('../img/cash.png');
}