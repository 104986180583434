.document {

    display: grid;
    grid-template-columns:  repeat(4, 1fr);;

}

.document__cell{
    border: 1px solid black;
}

.document__cell_number{
    text-align: right;
    font-weight: 600;
    padding-right: 5px;
}

.document__title{
    grid-column-start: 1;
    grid-column-end: -1;
    font-weight: 600;   
}

.document__comment{
    grid-column-start: 1;
    grid-column-end: -1;   

    color: red;
    font-weight: 600;
}

.document__route-info{
    color: black;
    font-size: 8px;
}