.button{
    margin-top: 10px;
    display: block;
    width: 100%;
    background: #59A4F2;
    border-radius: 10px;
    padding: 12px;
    border-color: transparent;

    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.button:hover{
    cursor: pointer;
    background: #FFFFFF;
    color: #59A4F2;
    border-color: #59A4F2;

}

.button:disabled{
    opacity: 0.2;
    cursor:not-allowed;
}