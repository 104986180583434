.label{
    font-size: 20px;
    align-self: center;
    justify-self: center;
}

.input{
    padding-top: 5px;
      padding-bottom: 5px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
        border: 1px solid rgb(92, 99, 97);

        font-size: 22px; 
}

.input:focus{
    outline: 2px solid #59A4F2;
}