.task{
    padding: 5px;
    margin-bottom: 20px;
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0px 3px 7px gray;
}

.task__link{
    color: black;
    text-decoration: none;
}

.task__link_stricted{
    text-decoration: line-through;
}

.task__contractor{
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 5px;   
}

.task__address{
    font-weight: 600;
    margin-bottom: 3px;
}

.task__delivery-point{
    font-size: 10px;
}

.task__comment{
    color: red;
    font-weight: 500;
    font-size: 20px;
}